const initial ={
    'guidePages':{
        "edges": [
            {
                "node": {
                    "title": "Higher Interest Rates And Remortgaging Options",
                    "seoGuides": {
                        "url": "mortgage-guides/higher-interest-rates-and-remortgaging-options",
                        "guideDescription": "As of the August of 2023, the Bank of England has increased its base rate for the 14th consecutive time, making it more difficult for borrowers to get good mortgage deals. The current base rate of 5.25% is the highest it has ever been since March 2008. Speaking of high rates, the average two-year fixed mortgage rate in the UK is now at 6.85%, crossing the peak of 6.55% in October.",
                        "showOnHomepage": null,
                        "guideCategory": [
                            "remortgaging"
                        ],
                        "createdDate": "07/10/2023"
                    }
                }
            },
            {
                "node": {
                    "title": "What is APRC?",
                    "seoGuides": {
                        "url": "mortgage-guides/what-is-aprc",
                        "guideDescription": "APRC refers to the annual percentage rate. As the name suggests, it is the rate of interest a lender charges for their loan on an annual basis. This interest is often associated with financial instruments like credit cards, loans, mortgages, etc. It lets the borrowers know how much interest they will be charged for the amount borrowed annually.",
                        "showOnHomepage": null,
                        "guideCategory": [
                            "first-time-buyer",
                            "remortgaging"
                        ],
                        "createdDate": "31/07/2023"
                    }
                }
            },
            {
                "node": {
                    "title": "What is a credit score?",
                    "seoGuides": {
                        "url": "mortgage-guides/what-is-a-credit-score",
                        "guideDescription": "In simple words, a credit score is a number that represents your creditworthiness.\r\n\r\nThe number is given to every citizen of the UK above the age of 18, making them eligible for financial borrowing.\r\n\r\nYour credit score plays an important role in financial lenders approving your applications and lending you money. The higher your credit score is, the better your profile looks to a potential lender. High credit scores often convince lenders to lend money. On the other hand, a lower credit score may increase the chances of declining your mortgage application\r\n\r\nWhile every lender uses different methods to calculate your credit score, all of them use information obtained from three major credit reference agencies – Experian, Equifax, and TransUnion. These agencies work with building societies, banks, retailers, mobile phone companies, and other relevant entities to help them decide if a borrower is capable of repayments.",
                        "showOnHomepage": null,
                        "guideCategory": [
                            "first-time-buyer",
                            "mortgaging"
                        ],
                        "createdDate": "24/07/2023"
                    }
                }
            },
            {
                "node": {
                    "title": "What is loan to Value",
                    "seoGuides": {
                        "url": "mortgage-guides/what-is-loan-to-value",
                        "guideDescription": "When you are getting a mortgage  to purchase  or remortgage a property, the rate of interest and the deposit to be paid are the two most important factors that influence your decision. Both these factors decide the repayments you would be required to make over time.\r\n\r\nAnother highly important factor that dictates your choice and influences the two factors mentioned above is loan-to-value (LTV) or the LTV ratio. Every mortgage lender takes LTV into consideration before offering a suitable mortgage deal to the borrowers.\r\n\r\nSo, what is loan-to-value?",
                        "showOnHomepage": null,
                        "guideCategory": [
                            "first-time-buyer",
                            "remortgaging"
                        ],
                        "createdDate": "17/07/2023"
                    }
                }
            },
            {
                "node": {
                    "title": "Tenants In Common” And “Joint Tenants",
                    "seoGuides": {
                        "url": "mortgage-guides/tenants-in-common-and-joint-tenants",
                        "guideDescription": "Especially in the case of first-time buyers, people choose to purchase properties with their friends or partners. This helps them reduce the deposit paid by each individual and increases the amount they can borrow. While most people choose to partner with one other individual, it is possible for up to 4 individuals to be legal owners of a property.\r\n\r\nHowever, confusion between Tenants in Common and Joint Tenants is common when it comes to joint ownership. If you are planning to share the ownership of a property you purchase, it is important to understand whether you should go with Joint Tenants or Tenants in Common.",
                        "showOnHomepage": null,
                        "guideCategory": [
                            "property",
                            "remortgaging"
                        ],
                        "createdDate": "10/07/2023"
                    }
                }
            },
            {
                "node": {
                    "title": "Type of Survey",
                    "seoGuides": {
                        "url": "mortgage-guides/type-of-survey",
                        "guideDescription": "Conducting a house survey is one of the many important assessments that need to be made before going ahead with the purchase. Depending on the situation, property, and preferences, you can choose from the many types of building surveys available to you.\r\n\r\nWhat is a house survey?\r\n\r\nAs the name suggests, a house survey is an assessment of a property before it is purchased. While purchasing a house, you may need to conduct multiple surveys, such as a homebuyer survey, physical valuation for getting a mortgage, structural surveys, etc.",
                        "showOnHomepage": null,
                        "guideCategory": [
                            "property",
                            "first-time-buyer"
                        ],
                        "createdDate": "03/07/2023"
                    }
                }
            },
            {
                "node": {
                    "title": "First-time Buyers Tips",
                    "seoGuides": {
                        "url": "mortgage-guides/first-time-buyers-tips",
                        "guideDescription": "Activity Among First-time Buyers Is Increasing As Annual Price Increase Slows. Here are some tips for first time buyer.\r\n\r\nWhen it comes to purchasing properties, first-time buyers are often sensitive to property prices and the mortgage rate prevalent in the market. Property prices in the UK increase every year, adding to the value of houses and flats being sold. First-time buyers need to be conscious of this increase as it influences their overall cost of purchasing their first homes.\r\n\r\nIn March 2023, the average price of properties entering the market experienced a rise of 0.8%, which is below the monthly rise of 1% witnessed in March over the last two decades. This reflects greater pricing caution by property sellers across the country. While the 1.2% price jump in the top-of-the-ladder sector acts as an exception, properties for first-time buyers the ones in second-stepper sectors are seeing a slower annual price rise.",
                        "showOnHomepage": null,
                        "guideCategory": [],
                        "createdDate": "30/06/2023"
                    }
                }
            },
            {
                "node": {
                    "title": "Mortgage Jargon explained",
                    "seoGuides": {
                        "url": "mortgage-guides/mortgage-jargon-explained",
                        "guideDescription": "Mortgage Jargon Terms used .\r\n\r\nThe property market is no less than a field of study with important terminologies you should make yourself familiar with. Whether you are looking for a first-time buyer mortgage or wish to remortgage your house, it is advisable to know the jargon terms used by mortgage brokers, financial advisors, mortgage lenders, property dealers, and all other parties operating in the market.\r\n\r\nHere are some of the most common and important jargon terms you should know and remember as you apply for a mortgage to purchase your new house:\r\n\r\nAgreement In Principle",
                        "showOnHomepage": null,
                        "guideCategory": [
                            "mortgaging"
                        ],
                        "createdDate": "29/06/2023"
                    }
                }
            },
            {
                "node": {
                    "title": "How long it takes to remortgage",
                    "seoGuides": {
                        "url": "mortgage-guides/how-long-it-takes-to-remortgage",
                        "guideDescription": "What is remortgaging?\r\n\r\nRemortgaging is the process of switching an existing mortgage deal with a new one at the end of the fixed term. Clients often choose to remortgage their properties for better rates to save up on their monthly payments.\r\n\r\nSome of the common reason for remortgage are settling existing debts, making investments, or home improvements. We would bring the best remortgage deals at your disposal based on your requirements.\r\n\r\n\r\n\r\n\r\nHow long does it take to remortgage ?",
                        "showOnHomepage": null,
                        "guideCategory": [
                            "remortgaging"
                        ],
                        "createdDate": "26/06/2023"
                    }
                }
            },
            {
                "node": {
                    "title": "Shared ownership mortgages",
                    "seoGuides": {
                        "url": "mortgage-guides/shared-ownership-mortgages",
                        "guideDescription": "As the name suggests, a shared ownership mortgage is the type of mortgage where you share the ownership of the property with another individual(Housing Association). In other words, you purchase only a portion of the property on the mortgage and pay rent for the other.\r\n\r\nAlso known as “part buy, part rent”, a shared ownership mortgage makes you an owner and a tenant of the same property. It allows you to own the property without making a heavy deposit as you need to pay deposit only for  the share purchased by you. This mortgage is often preferred by first-time buyers as they are new to the market and often struggle with gathering a big sum of money for deposit.\r\n\r\nHousing associations allow you to purchase anywhere between 25% to 75% of a property and get a mortgage for the same. For the remaining 75% to 25% of the property, you will be required to pay rent to the housing association. In most cases, buyers are required to provide 5% of their share as a deposit.",
                        "showOnHomepage": null,
                        "guideCategory": [
                            "first-time-buyer"
                        ],
                        "createdDate": "19/06/2023"
                    }
                }
            },
            {
                "node": {
                    "title": "How To Staircase Shared Ownership?",
                    "seoGuides": {
                        "url": "mortgage-guides/how-to-staircase-shared-ownership",
                        "guideDescription": "As you know shared ownership property allows you to own a specific percentage of the house. The control you have as a shared owner is limited to your property share. If you want to increase your stake in the property, you can staircase shared ownership.\r\n\r\nWhat is staircasing in shared ownership?\r\n\r\nIn shared ownership, staircasing refers to the process of buying more shares in your property to own a greater portion of the property. Depending on the terms of your lease, you are allowed to staircase after living in the property for a specific duration. This allows you to have more ownership in the property by buying more shares.",
                        "showOnHomepage": null,
                        "guideCategory": [
                            "first-time-buyer",
                            "remortgaging"
                        ],
                        "createdDate": "12/06/2023"
                    }
                }
            },
            {
                "node": {
                    "title": "What Is Conveyancing",
                    "seoGuides": {
                        "url": "mortgage-guides/what-is-conveyancing",
                        "guideDescription": "Taking a mortgage for buying a property involves a range of legal considerations to make the process legitimate and smooth. Once you have selected the right property and the right mortgage for purchase, you will need to look after conveyancing for handling the legal matters.\r\n\r\nWhat is conveyancing?\r\n\r\nConveyancing is the legal process while buying or selling a property that ensures that the ownership of the property is successfully transferred from the seller/owner to the buyer. If you are planning to purchase a property on mortgage, conveyancing helps you become the legal owner of the property once you have secured your mortgage all arrangements have been made.",
                        "showOnHomepage": null,
                        "guideCategory": [
                            "property",
                            "first-time-buyer",
                            "remortgaging"
                        ],
                        "createdDate": "05/06/2023"
                    }
                }
            },
            {
                "node": {
                    "title": "Contractor Mortgage",
                    "seoGuides": {
                        "url": "mortgage-guides/contractor-mortgage-2",
                        "guideDescription": "When it comes to mortgages, people have always taken into consideration borrowers who are salaried employees. However, as more and more professionals are becoming contractors, this notion is changing.\r\n\r\nIf you are a contractor and want to buy a property, you can do so by taking a contractor mortgage. Work with your mortgage broker and look for a lender who provides contractor mortgages at deals that are suitable for your circumstances.\r\n\r\nWhat is a contractor mortgage?\r\n\r\nAs the name suggests, a contractor mortgage is a type of mortgage given to contractors. Here, the mortgage lenders provide mortgage to contractors based on the type of contracts they are on, the consistency of their work, and other relevant factors.",
                        "showOnHomepage": null,
                        "guideCategory": [
                            "first-time-buyer"
                        ],
                        "createdDate": "29/05/2023"
                    }
                }
            },
            {
                "node": {
                    "title": "NHS mortgage",
                    "seoGuides": {
                        "url": "mortgage-guides/nhs-mortgage",
                        "guideDescription": "What is an NHS mortgage?\r\n\r\nNHS mortgage is not a standalone mortgage product. It refers to a situation wherein a mortgage deal is offered to an NHS employee. NHS employees often struggle with hospital rotations, inadequate incomes, salary band restrictions, short contracts, and other relevant issues. NHS mortgages are initiatives to make property purchases easier for these individuals.\r\n\r\nThese mortgages involve lenders offering discounts or incentives to borrowers who are NHS employees. However, not all lenders provide these services. If you work for the NHS, make sure you look for a mortgage or mortgage broker who can connect you with a suitable lender.",
                        "showOnHomepage": null,
                        "guideCategory": [
                            "remortgaging",
                            "mortgaging"
                        ],
                        "createdDate": "22/05/2023"
                    }
                }
            },
            {
                "node": {
                    "title": "Right to buy mortgage",
                    "seoGuides": {
                        "url": "mortgage-guides/right-to-buy-mortgage",
                        "guideDescription": "A right to buy mortgage is a government mortgage scheme that allows council tenants in the UK to purchase the houses they live in. These properties are often sold at a discount and in many cases, the tenants are not required to pay the deposit as well. Here, the councils let the mortgage borrowers put the discount offered to them towards the purchase price of the property.\r\n\r\nA right to buy mortgage is subject to the same eligibility criteria that are applicable for a normal mortgage deal. The discount offered to the council tenants depends on a range of different factors, such as the type of property to be purchased, the location of the property, the value of the property at the time of application, and more.\r\n\r\nIt is advisable to work with an experienced mortgage broker to find the right mortgage lender and choose a deal that best suits your circumstances.",
                        "showOnHomepage": null,
                        "guideCategory": [
                            "first-time-buyer",
                            "remortgaging",
                            "mortgaging"
                        ],
                        "createdDate": "15/05/2023"
                    }
                }
            },
            {
                "node": {
                    "title": "What is green mortgage",
                    "seoGuides": {
                        "url": "mortgage-guides/what-is-green-mortgage",
                        "guideDescription": "Over time, the importance of sustainable development has increased worldwide. Green mortgages help you take a step toward sustainable living, keeping track of the impact we have on the environment around us.\r\n\r\nLenders today prefer giving green mortgages to borrowers to instill a sense of awareness about the depletion of non-renewable resources.\r\n\r\nLet’s know more about green mortgage.\r\n\r\nWhat is a green mortgage?",
                        "showOnHomepage": null,
                        "guideCategory": [
                            "property"
                        ],
                        "createdDate": "08/05/2023"
                    }
                }
            },
            {
                "node": {
                    "title": "Remortgage with bad credit",
                    "seoGuides": {
                        "url": "mortgage-guides/remortgage-with-bad-credit",
                        "guideDescription": "Your credit score arguably plays the most important role in finding an ideal mortgage . Lenders often turn down borrowers with poor credit scores as they are not willing to take any risks in lending them money. It is therefore important to make all your credit payments on time and maintain a good credit score as it is vital for remortgaging your property.\r\nHowever, if the damage is already done and you have acquired bad credit, there is no need to lose hope. People often feel that their chances of getting a remortgage deal go down to zero in the case of bad credit. This is certainly not the case. Rest assured that it is possible to remortgage with bad credit by working with the right Remortgage brokers.",
                        "showOnHomepage": null,
                        "guideCategory": [
                            "property",
                            "mortgaging"
                        ],
                        "createdDate": "09/04/2023"
                    }
                }
            },
            {
                "node": {
                    "title": "Remortgage for home improvement",
                    "seoGuides": {
                        "url": "mortgage-guides/remortgage-for-home-improvements",
                        "guideDescription": "There are many reasons why people choose to remortgage their properties, one of the most common being is home improvements. Home improvements can get quite expensive and demand a lot of money out.\r\nEspecially if you have growing family extra space is always needed adding a loft or rear, side extension will give a lot more space.\r\n\r\nHow should you remortgage for home improvements?\r\nThe process of remortgaging for home improvements is the same as carrying out a regular remortgage. You need to get in touch with your mortgage broker for remortgage and additional funds application.\r\nIf you are planning to switch to a new mortgage lender or stay with the same lender, you will have to give valid reasons to your borrowing application is successful.",
                        "showOnHomepage": null,
                        "guideCategory": [
                            "property",
                            "remortgaging",
                            "mortgaging"
                        ],
                        "createdDate": "07/04/2023"
                    }
                }
            },
            {
                "node": {
                    "title": "What is offset mortgage",
                    "seoGuides": {
                        "url": "mortgage-guides/what-is-offset-mortgage",
                        "guideDescription": "Offset mortgage is a type of mortgage where your savings or current bank account is linked with your mortgage account. Here, the money you saved is used to offset your mortgage repayments. This savings account linked to your mortgage account is referred to as the “offset account.”\r\nOne of the biggest reasons why borrowers opt for an offset mortgage is that it helps them cut down the interest to be paid on their mortgage. When you get yourself an offset mortgage, the lender would deduct money in your savings account from your outstanding mortgage balance to give you a net balance. Then, the mortgage interest is charged on this net balance instead of the total outstanding mortgage balance. This reduces the mortgage interest and helps you save more money.",
                        "showOnHomepage": null,
                        "guideCategory": [
                            "remortgaging",
                            "mortgaging"
                        ],
                        "createdDate": "05/04/2023"
                    }
                }
            },
            {
                "node": {
                    "title": "What is variable rate mortgage?",
                    "seoGuides": {
                        "url": "mortgage-guides/what-is-a-variable-rate-mortgage",
                        "guideDescription": "As opposed to a fixed-rate mortgage, a variable rate mortgage is where the interest rate keeps fluctuating over time. Here, your monthly mortgage repayments are not fixed and keep changing as the interest rate of your lender keeps changing.\r\nWith variable mortgages your monthly payment can go down if the interest rates go down, you may end up paying more interest over time if the rates go up. Applicant should be prepared for both scenarios if thinking of variable rate mortgage deal.",
                        "showOnHomepage": null,
                        "guideCategory": [
                            "mortgaging"
                        ],
                        "createdDate": "03/04/2023"
                    }
                }
            },
            {
                "node": {
                    "title": "What is a fixed rate mortgage?",
                    "seoGuides": {
                        "url": "mortgage-guides/what-is-a-fixed-rate-mortgage",
                        "guideDescription": "As the name suggests, a fixed-rate mortgage is a type of mortgage where the rate of interest remains the same for a pre-decided period of time. This period is known as the fixed term of the mortgage.\r\nWhile many lenders offer fixed terms between 2,3 and 5 years, some mortgage lenders stretch it as long as 10 years or more.\r\nOne of the biggest benefits of getting a fixed-rate mortgage is that your monthly repayments do not change even if the interest rate in the market (and of your lender) changes. This helps you plan your monthly budget better as there will be no unexpected rise in your mortgage payments. You can compare monthly mortgage payments to See how much you will pay per month on latest mortgage rates comparison",
                        "showOnHomepage": null,
                        "guideCategory": [
                            "remortgaging",
                            "mortgaging"
                        ],
                        "createdDate": "01/04/2023"
                    }
                }
            },
            {
                "node": {
                    "title": "What is a buy to let remortgage?",
                    "seoGuides": {
                        "url": "mortgage-guides/what-is-a-buy-to-let-remortgage",
                        "guideDescription": "As the name suggests, a buy-to-let remortgage is a process of remortgaging our buy-to-let property. It involves switching one buy-to-let mortgage deal with another, provided the new deal provides you with more benefits. You may choose to remortgage with the same lender or switch to a new lender based on your requirements.\r\n\r\nHow does buy-to-let remortgage work?\r\nBuy-to-let remortgage is like any other conventional remortgage, the only difference being that the property here is a buy-to-let property. As long as you have the required deposit for the new mortgage (which is often higher than other mortgage deals and ranges from 20% to 40%) and meet all the eligibility criteria of the lender, you can go ahead with a buy-to-let remortgage.",
                        "showOnHomepage": null,
                        "guideCategory": [
                            "remortgaging"
                        ],
                        "createdDate": "30/03/2023"
                    }
                }
            },
            {
                "node": {
                    "title": "Guide to Gifted Deposit",
                    "seoGuides": {
                        "url": "mortgage-guides/guide-to-gifted-deposit",
                        "guideDescription": "Deposits often play a decisive role in finalizing a borrower’s decision especially in the case of first-time buyers, mortgage down payments are often highly important and worrisome factors. Mortgage deposit gifts reduce this burden to a great extent, making the process of getting a mortgage a lot easier. If you are facing a financial crunch while getting a mortgage for your property, a mortgage deposit gift from your family members can be a great relief. On the other hand, if any of your family members need help in making the down payment, you can gift them a mortgage deposit.\r\nWhat is a gifted deposit?",
                        "showOnHomepage": null,
                        "guideCategory": [
                            "first-time-buyer",
                            "mortgaging"
                        ],
                        "createdDate": "28/03/2023"
                    }
                }
            },
            {
                "node": {
                    "title": "What is a transfer of equity?",
                    "seoGuides": {
                        "url": "mortgage-guides/what-is-a-transfer-of-equity",
                        "guideDescription": "There are many ways of changing the legal ownership of your property without putting it up for sale in the market. You may want to add the name of your partner as a joint owner after getting married. You may want to give your children stakes in your property. You may want to completely transfer the ownership of your property without selling it off.\r\nWhatever the case is, you will need to go through the process of transfer of equity if you are willing to change the legal ownership of your property. Before understanding the transfer of equity, let us briefly understand what equity means.",
                        "showOnHomepage": null,
                        "guideCategory": [
                            "remortgaging",
                            "mortgaging"
                        ],
                        "createdDate": "26/03/2023"
                    }
                }
            },
            {
                "node": {
                    "title": "What documents required to Remortgage?",
                    "seoGuides": {
                        "url": "mortgage-guides/what-documents-required-to-remortgage",
                        "guideDescription": "Remortgaging is the process of switching an existing mortgage deal with a new one at the end of the fixed term.\r\nMost people often choose to remortgage their property for better rates. Some people choose to stay with same lender, but some fail to remortgage on time resulting a lot more monthly mortgage payment.\r\nUsually remortgage will involve remortgaging with same lender or change the lender. Remortgage with same lender is called product transfer. Which is simple process and required less paperwork compared to remortgage to different lender.",
                        "showOnHomepage": null,
                        "guideCategory": [
                            "property",
                            "remortgaging"
                        ],
                        "createdDate": "24/03/2023"
                    }
                }
            },
            {
                "node": {
                    "title": "Leasehold property guide",
                    "seoGuides": {
                        "url": "mortgage-guides/leasehold-property-guide",
                        "guideDescription": "If you are planning to purchase a new property with a mortgage, it is important to consider the type of home ownership you want to go for.\r\nProperty ownership involves two major forms of ownership – freehold and leasehold\r\nIn simple words, freehold property where you own the property and land it on. Leasehold property is where you own the property you live but not a land.\r\n\r\nFactors worth considering with leasehold property",
                        "showOnHomepage": null,
                        "guideCategory": [
                            "property",
                            "first-time-buyer"
                        ],
                        "createdDate": "22/03/2023"
                    }
                }
            },
            {
                "node": {
                    "title": "What is an EPC",
                    "seoGuides": {
                        "url": "mortgage-guides/what-is-an-epc",
                        "guideDescription": "In current times energy and power consumption means a lot to normal household.\r\nWith increasing cost of gas and electric homeowners preferred properties which retain more energy as opposed to the ones that don’t.\r\n\r\nTo showcase the efficiency of energy consumption by the houses, every house is issued an EPC rating.\r\nLet’s understand what EPC is and how does it affect us.",
                        "showOnHomepage": null,
                        "guideCategory": [
                            "property",
                            "first-time-buyer"
                        ],
                        "createdDate": "20/03/2023"
                    }
                }
            },
            {
                "node": {
                    "title": "Buying a leasehold property",
                    "seoGuides": {
                        "url": "mortgage-guides/guide-to-buying-aleasehold-property",
                        "guideDescription": "One of the most common ways of property purchase is a owning leasehold property. If you buying a property for the first time, it is important to seek help from mortgage brokers and advisors.\r\n\r\nA skilled and experienced mortgage broker would scan the whole market and get you the best first-time buyer mortgage deals based on your requirements and circumstances.\r\n\r\nIf you are planning to own a leasehold property for the first time, here is a small yet informative guide to buying a leasehold property:",
                        "showOnHomepage": null,
                        "guideCategory": [
                            "property",
                            "first-time-buyer"
                        ],
                        "createdDate": "11/05/2022"
                    }
                }
            },
            {
                "node": {
                    "title": "Leasehold Vs Freehold",
                    "seoGuides": {
                        "url": "mortgage-guides/leasehold-vs-freehold",
                        "guideDescription": "Leasehold Vs Freehold:\r\n\r\nWhen it comes to owning a house, there are two major forms of ownership involved – freehold and leasehold. This determines the extent of your ownership of the house you live in.\r\n\r\nIn other words, freehold and leasehold properties determine whether the property (and land) you live in is your own or if you have a landlord for the same.",
                        "showOnHomepage": null,
                        "guideCategory": [
                            "property"
                        ],
                        "createdDate": "29/04/2022"
                    }
                }
            },
            {
                "node": {
                    "title": "Are you mortgage-ready ?",
                    "seoGuides": {
                        "url": "mortgage-guides/are-you-mortgage-ready",
                        "guideDescription": "Are you mortgage ready ?\r\n\r\nBuying a new home is, in equal parts, exciting and full of responsibilities. If you are planning to get a mortgage for your new house, it is important to be patient, alert, and precise in your approach.\r\nMoreover, it is advisable to work with a skilled and experienced mortgage broker in your city.\r\n\r\nFor example, if you are looking forward to buying a property in Harrow, make sure you hire the best mortgage broker Harrow has to offer based on your requirements.",
                        "showOnHomepage": null,
                        "guideCategory": [
                            "first-time-buyer",
                            "mortgaging"
                        ],
                        "createdDate": "28/03/2022"
                    }
                }
            },
            {
                "node": {
                    "title": "What can stop you getting mortgage",
                    "seoGuides": {
                        "url": "mortgage-guides/what-can-stop-you-getting-mortgage",
                        "guideDescription": "Purchasing a property through a mortgage is a significant decision that requires careful consideration, especially if you are not a cash buyer. Most of us fund the property purchase via deposit and mortgage.\r\n\r\nBut what can stop you from getting a mortgage?\r\n\r\nThere are several considerations one should keep in mind before going ahead and making the application.\r\nAs the market is highly in demand and growing property prices, it is advisable to work with a reliable,\r\ndedicated, and experienced local mortgage broker.",
                        "showOnHomepage": null,
                        "guideCategory": [
                            "mortgaging"
                        ],
                        "createdDate": "18/03/2022"
                    }
                }
            },
            {
                "node": {
                    "title": "Remortgage with same lender?",
                    "seoGuides": {
                        "url": "mortgage-guides/is-it-good-to-remortgage-with-same-lender",
                        "guideDescription": "When it comes to remortgaging your home, there are two major ways in which you can go about it.\r\nYou can either stay with the same lender and replace your existing mortgage deal with a new one or find a new lender and get yourself a new deal.\r\nBased on your circumstances, it is important to choose the most feasible option.\r\nWork with a reliable and capable remortgage broker to guide you in making the right decision.\r\n\r\nLet us start by understanding is its good idea remortgaging with same lender?",
                        "showOnHomepage": null,
                        "guideCategory": [
                            "remortgaging"
                        ],
                        "createdDate": "17/03/2022"
                    }
                }
            },
            {
                "node": {
                    "title": "Facts About Mortgages",
                    "seoGuides": {
                        "url": "mortgage-guides/facts-about-mortgages",
                        "guideDescription": "Getting a mortgage is always an important event in an individual’s life. It is, therefore, common for people to get excited and confused at the same time. Especially if you are getting a mortgage for the first time, it is always important to be familiar with the manner in which the process works.\r\n\r\nMoreover, is important to seek help from professional advisors. For example, if you are looking for a property in Hounslow, look for the best mortgage advisor Hounslow has to offer before taking any action.",
                        "showOnHomepage": null,
                        "guideCategory": [
                            "mortgaging"
                        ],
                        "createdDate": "08/03/2022"
                    }
                }
            },
            {
                "node": {
                    "title": "Remortgage cost and saving",
                    "seoGuides": {
                        "url": "mortgage-guides/remortgage-cost-and-savings",
                        "guideDescription": "Remortgage can take few months. we have cover in this guide the cost and saving related to remortgage.\r\n\r\nIt is good idea to shop around 3 -4 months in advance. Compare the mortgage deals and know the cost involve.\r\n\r\nIn this guide we will look at,\r\nRemortgage cost.\r\nHow much you save.",
                        "showOnHomepage": null,
                        "guideCategory": [
                            "property",
                            "remortgaging"
                        ],
                        "createdDate": "04/03/2022"
                    }
                }
            },
            {
                "node": {
                    "title": "Mortgage broker",
                    "seoGuides": {
                        "url": "mortgage-guides/mortgage-broker-guide",
                        "guideDescription": "If buying your first property or remortgaging to better rate MariannaFS offer fee free online mortgage service.\r\n\r\n\r\n\r\n\r\nWhat is mortgage broker? \r\n\r\nIn simple words mortgage broker are who acts as middle person between lender and client.\r\n\r\nMortgage broker can find and help you to choose right mortgage for your circumstances.",
                        "showOnHomepage": null,
                        "guideCategory": [
                            "first-time-buyer",
                            "mortgaging"
                        ],
                        "createdDate": "01/03/2022"
                    }
                }
            },
            {
                "node": {
                    "title": "Compare mortgage rates",
                    "seoGuides": {
                        "url": "tools/rates",
                        "guideDescription": null,
                        "showOnHomepage": null,
                        "guideCategory": [],
                        "createdDate": "01/03/2022"
                    }
                }
            },
            {
                "node": {
                    "title": "Calculators",
                    "seoGuides": {
                        "url": "tools/calculators",
                        "guideDescription": null,
                        "showOnHomepage": null,
                        "guideCategory": [],
                        "createdDate": "01/03/2022"
                    }
                }
            },
            {
                "node": {
                    "title": "Agreement in principle",
                    "seoGuides": {
                        "url": "mortgage-guides/agreement-in-principle",
                        "guideDescription": "Mortgage in principle, Decision in principle or Agreement in principal. MIP, DIP or AIP.\r\n\r\nWith the current market situation house prices are at its highest many estate agents wants to know if you are mortgage ready.\r\n\r\nEstate agents will ask you for mortgage in principle before submitting your offer.\r\n\r\nwhat is mortgage in principal?\r\n\r\nA mortgage in principle is preapproval of your mortgage from lender which tells you roughly how much you may able to borrow.",
                        "showOnHomepage": null,
                        "guideCategory": [
                            "first-time-buyer",
                            "mortgaging"
                        ],
                        "createdDate": "02/03/2022"
                    }
                }
            },
            {
                "node": {
                    "title": "Remortgage guide",
                    "seoGuides": {
                        "url": "mortgage-guides/remortgaging-guide",
                        "guideDescription": "Your monthly mortgage repayments can be a substantial amount of your monthly income, so you should check for better deals from other lenders that will allow you to make lower repayments on better terms.\r\n\r\nOur expert mortgage brokers will find you the best remortgage deals  for your special needs and seamlessly move you to your new mortgage deal best of all our services are free we do not charge broker fee.",
                        "showOnHomepage": true,
                        "guideCategory": [
                            "remortgaging"
                        ],
                        "createdDate": "01/03/2022"
                    }
                }
            },
            {
                "node": {
                    "title": "Btl buyer guide",
                    "seoGuides": {
                        "url": "mortgage-guides/buy-to-let-guide",
                        "guideDescription": "Finding the right buy-to-let mortgage can be a daunting task. Our trusted mortgage broker can quickly find you the most exclusive deals, provide prompt, worry-free services and do all the hard work for you.\r\n\r\n\r\n\r\n\r\nIn this guide we cover,\r\n\r\n\r\n\r\n\r\nBuy to let\r\nBuy to let mortgage broker\r\nCost of buy to let",
                        "showOnHomepage": true,
                        "guideCategory": [
                            "property"
                        ],
                        "createdDate": "01/03/2022"
                    }
                }
            },
            {
                "node": {
                    "title": "Home mover guide",
                    "seoGuides": {
                        "url": "mortgage-guides/home-mover-guide",
                        "guideDescription": "Whether you are moving to a bigger property or downgrading to smaller property, moving home can be a busy and stressful time — and mortgage is the first thing you will worry about.\r\n\r\nOur experienced advisors will find the best value home mover mortgage for your needs and seamlessly move your mortgage to your new property.\r\n\r\nso that you can relax and focus on turning your new house into a home.",
                        "showOnHomepage": true,
                        "guideCategory": [
                            "property"
                        ],
                        "createdDate": "01/03/2022"
                    }
                }
            },
            {
                "node": {
                    "title": "First time buyer guide",
                    "seoGuides": {
                        "url": "mortgage-guides/first-time-buyer-guide",
                        "guideDescription": "Buying your first house is one of the most important purchases of your life and can be equally exciting and daunting.\r\n\r\nOur experienced first-time buyer mortgage advisor at MariannaFS can guide you through the process. Remember getting a mortgage secure is main part of the buying process.\r\n\r\nYou should always look at the mortgage options and try to understand how the whole process works.\r\nSo here is all you need to know about your first mortgage.",
                        "showOnHomepage": true,
                        "guideCategory": [
                            "first-time-buyer"
                        ],
                        "createdDate": "01/03/2022"
                    }
                }
            }
        ]
    },
    'localPages':[
        {
            "id": "cG9zdDoxNTEy",
            "title": "Brent",
            "pageExtras": {
                "bannerImage": {
                    "sourceUrl": "https://mariannafs.co.uk/api/wp-content/uploads/2023/09/L5.png"
                }
            },
            "slug": "mortgage-broker-brent"
        },
        {
            "id": "cG9zdDoxNTA5",
            "title": "Kingston",
            "pageExtras": {
                "bannerImage": {
                    "sourceUrl": "https://mariannafs.co.uk/api/wp-content/uploads/2023/09/L4.png"
                }
            },
            "slug": "mortgage-broker-kingston"
        },
        {
            "id": "cG9zdDoxNTA2",
            "title": "Harrow",
            "pageExtras": {
                "bannerImage": {
                    "sourceUrl": "https://mariannafs.co.uk/api/wp-content/uploads/2023/09/L6.png"
                }
            },
            "slug": "mortgage-broker-harrow"
        },
        {
            "id": "cG9zdDoxNTAz",
            "title": "Hounslow",
            "pageExtras": {
                "bannerImage": {
                    "sourceUrl": "https://mariannafs.co.uk/api/wp-content/uploads/2023/09/L2.png"
                }
            },
            "slug": "mortgage-broker-hounslow"
        },
        {
            "id": "cG9zdDoxNTAw",
            "title": "Slough",
            "pageExtras": {
                "bannerImage": {
                    "sourceUrl": "https://mariannafs.co.uk/api/wp-content/uploads/2023/09/L8.png"
                }
            },
            "slug": "mortgage-broker-slough"
        },
        {
            "id": "cG9zdDoxNDkz",
            "title": "Richmond",
            "pageExtras": {
                "bannerImage": {
                    "sourceUrl": "https://mariannafs.co.uk/api/wp-content/uploads/2023/09/Group-23.png"
                }
            },
            "slug": "mortgage-broker-richmond"
        },
        {
            "id": "cG9zdDoxNDg5",
            "title": "London",
            "pageExtras": {
                "bannerImage": {
                    "sourceUrl": "https://mariannafs.co.uk/api/wp-content/uploads/2023/09/L7.png"
                }
            },
            "slug": "mortgage-broker-london"
        },
        {
            "id": "cG9zdDoxMzQx",
            "title": "Ealing",
            "pageExtras": {
                "bannerImage": {
                    "sourceUrl": "https://mariannafs.co.uk/api/wp-content/uploads/2023/09/L3.png"
                }
            },
            "slug": "mortgage-broker-ealing"
        }
    ],
    'additionalBranch':[
        {
            "id": "cG9zdDozMzk4",
            "title": "Surrey",
            "pageExtras": {
                "bannerImage": {
                    "sourceUrl": "https://mariannafs.co.uk/api/wp-content/uploads/2023/10/L12-New_HMT.png"
                }
            },
            "slug": "mortgage-broker-surrey"
        },
        {
            "id": "cG9zdDozMzgx",
            "title": "Wandsworth",
            "pageExtras": {
                "bannerImage": {
                    "sourceUrl": "https://mariannafs.co.uk/api/wp-content/uploads/2023/10/L13-Enfield.png"
                }
            },
            "slug": "mortgage-broker-wandsworth"
        },
        {
            "id": "cG9zdDozMjk4",
            "title": "Watford",
            "pageExtras": {
                "bannerImage": {
                    "sourceUrl": "https://mariannafs.co.uk/api/wp-content/uploads/2023/10/L9-Bromley.png"
                }
            },
            "slug": "mortgage-broker-watford"
        },
        {
            "id": "cG9zdDozNDc1",
            "title": "Hammersmith",
            "pageExtras": {
                "bannerImage": {
                    "sourceUrl": "https://mariannafs.co.uk/api/wp-content/uploads/2023/10/L12-New_HMT.png"
                }
            },
            "slug": "mortgage-broker-hammersmith"
        },
        {
            "id": "cG9zdDozNDAy",
            "title": "Reading",
            "pageExtras": {
                "bannerImage": {
                    "sourceUrl": "https://mariannafs.co.uk/api/wp-content/uploads/2023/10/L9-Bromley.png"
                }
            },
            "slug": "mortgage-broker-reading"
        },
        {
            "id": "cG9zdDozMzY0",
            "title": "Redbridge",
            "pageExtras": {
                "bannerImage": {
                    "sourceUrl": "https://mariannafs.co.uk/api/wp-content/uploads/2023/10/L15-Surrey.png"
                }
            },
            "slug": "mortgage-broker-redbridge"
        },
        {
            "id": "cG9zdDozNDE0",
            "title": "Hackney",
            "pageExtras": {
                "bannerImage": {
                    "sourceUrl": "https://mariannafs.co.uk/api/wp-content/uploads/2023/10/L13-Enfield.png"
                }
            },
            "slug": "mortgage-broker-hackney"
        },
        {
            "id": "cG9zdDozNDEx",
            "title": "Greenwich",
            "pageExtras": {
                "bannerImage": {
                    "sourceUrl": "https://mariannafs.co.uk/api/wp-content/uploads/2023/10/L15-Surrey.png"
                }
            },
            "slug": "mortgage-broker-greenwich"
        },
        {
            "id": "cG9zdDozMzc1",
            "title": "Guilford",
            "pageExtras": {
                "bannerImage": {
                    "sourceUrl": "https://mariannafs.co.uk/api/wp-content/uploads/2023/10/L10-Guilford.png"
                }
            },
            "slug": "mortgage-broker-guilford"
        },
        {
            "id": "cG9zdDozMzg5",
            "title": "Enfield",
            "pageExtras": {
                "bannerImage": {
                    "sourceUrl": "https://mariannafs.co.uk/api/wp-content/uploads/2023/10/L9-Bromley.png"
                }
            },
            "slug": "mortgage-broker-enfield"
        },
        {
            "id": "cG9zdDozMzYw",
            "title": "East Ham",
            "pageExtras": {
                "bannerImage": {
                    "sourceUrl": "https://mariannafs.co.uk/api/wp-content/uploads/2023/10/L9-Bromley.png"
                }
            },
            "slug": "mortgage-broker-east-ham"
        },
        {
            "id": "cG9zdDozMjY0",
            "title": "Croydon",
            "pageExtras": {
                "bannerImage": {
                    "sourceUrl": "https://mariannafs.co.uk/api/wp-content/uploads/2023/10/L12-New_HMT.png"
                }
            },
            "slug": "mortgage-broker-croydon"
        },
        {
            "id": "cG9zdDozNDA3",
            "title": "Bexley",
            "pageExtras": {
                "bannerImage": {
                    "sourceUrl": "https://mariannafs.co.uk/api/wp-content/uploads/2023/10/L12-New_HMT.png"
                }
            },
            "slug": "mortgage-broker-bexley"
        },
        {
            "id": "cG9zdDozMzcw",
            "title": "Bromley",
            "pageExtras": {
                "bannerImage": {
                    "sourceUrl": "https://mariannafs.co.uk/api/wp-content/uploads/2023/10/L9-Bromley.png"
                }
            },
            "slug": "mortgage-broker-bromley"
        },
        {
            "id": "cG9zdDozMzU0",
            "title": "Camden",
            "pageExtras": {
                "bannerImage": {
                    "sourceUrl": "https://mariannafs.co.uk/api/wp-content/uploads/2023/10/L12-New_HMT.png"
                }
            },
            "slug": "mortgage-broker-camden"
        },
        {
            "id": "cG9zdDozNDE4",
            "title": "Berkshire",
            "pageExtras": {
                "bannerImage": {
                    "sourceUrl": "https://mariannafs.co.uk/api/wp-content/uploads/2023/10/L15-Surrey.png"
                }
            },
            "slug": "mortgage-broker-berkshire"
        },
        {
            "id": "cG9zdDozMzkz",
            "title": "Barnet",
            "pageExtras": {
                "bannerImage": {
                    "sourceUrl": "https://mariannafs.co.uk/api/wp-content/uploads/2023/10/L14-Barnet.png"
                }
            },
            "slug": "mortgage-broker-barnet"
        },
        {
            "id": "cG9zdDozMzg2",
            "title": "Barking",
            "pageExtras": {
                "bannerImage": {
                    "sourceUrl": "https://mariannafs.co.uk/api/wp-content/uploads/2023/10/L15-Surrey.png"
                }
            },
            "slug": "mortgage-broker-barking"
        }
    ]
}
const seoGuidePages = (state = initial, action)=>{
    switch(action.type){
        // case 'SEO_GUIDE_PAGES':
        //     return state = action.data;
        case 'SEO_GUIDE_PAGES':
            return {
                ...state,
                'guidePages' : action.data
            };
        case 'SEO_LOCAL_PAGES':
            return {
                ...state,
                'localPages' : action.data
            };
        case 'SEO_Additional_Branch_Pages':
            return {
                ...state,
                'additionalBranch' : action.data
            };
        default: 
            return state;
    }
}

export default seoGuidePages;