const initial = {
    "id": "dGVybTo2",
    "databaseId": 6,
    "name": "Links",
    "slug": "links",
    "count": 7,
    "menuItems": {
        "nodes": [
            {
                "id": "cG9zdDo3Mw==",
                "label": "Home",
                "parentId": null,
                "path": "/home/",
                "title": null,
                "cssClasses": [],
                "connectedNode": {
                    "node": {
                        "uri": "/home/"
                    }
                }

            },
            {
                "id": "cG9zdDo3MQ==",
                "label": "Calculators",
                "parentId": null,
                "path": "/calculators/",
                "title": null,
                "cssClasses": [],
                "connectedNode": {
                    "node": {
                        "uri": "/calculators/"
                    }
                }

            },
            {
                "id": "cG9zdDo4MTg=",
                "label": "Contact",
                "parentId": null,
                "path": "/contact/",
                "title": null,
                "cssClasses": [],
                "connectedNode": {
                    "node": {
                        "uri": "/contact/"
                    }
                }

            },
            {
                "id": "cG9zdDoxNTI0",
                "label": "Terms Of Business",
                "parentId": null,
                "path": "/terms-of-business/",
                "title": null,
                "cssClasses": [],
                "connectedNode": {
                    "node": {
                        "uri": "/terms-of-business/"
                    }
                }

            },
            {
                "id": "cG9zdDo0NzE=",
                "label": "Privacy Notice",
                "parentId": null,
                "path": "/privacy-notice/",
                "title": null,
                "cssClasses": [],
                "connectedNode": {
                    "node": {
                        "uri": "/privacy-notice/"
                    }
                }

            },
            {
                "id": "cG9zdDo0Nzk=",
                "label": "Cookie Policy",
                "parentId": null,
                "path": "/cookie-policy/",
                "title": null,
                "cssClasses": [],
                "connectedNode": {
                    "node": {
                        "uri": "/cookie-policy/"
                    }
                }

            },
            {
                "id": "cG9zdDo0Nzg=",
                "label": "Complaints Procedure",
                "parentId": null,
                "path": "/complaints-procedure/",
                "title": null,
                "cssClasses": [],
                "connectedNode": {
                    "node": {
                        "uri": "/complaints-procedure/"
                    }
                }
            }
        ]
    }
};
const footerMenu = (state = initial, action) => {
    switch (action.type) {
        case 'FOOTER_MENU':
            return state = action.data;
        default:
            return state;
    }
}

export default footerMenu;