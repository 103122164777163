const initial = {
    "id": "dGVybTo1",
    "databaseId": 5,
    "name": "Services",
    "slug": "services",
    "count": 4,
    "menuItems": {
        "edges": [
            {
                "node": {
                    "id": "cG9zdDo4Ng==",
                    "label": "First time buyer",
                    "parentId": null,
                    "path": "/first-time-buyer/",
                    "title": null,
                    "cssClasses": [],
                    "connectedNode": {
                        "node": {
                            "uri": "/first-time-buyer/"
                        }
                    }
                }
            },
            {
                "node": {
                    "id": "cG9zdDo4NQ==",
                    "label": "Remortgage",
                    "parentId": null,
                    "path": "/remortgaging/",
                    "title": null,
                    "cssClasses": [],
                    "connectedNode": {
                        "node": {
                            "uri": "/remortgaging/"
                        }
                    }
                }
            },
            {
                "node": {
                    "id": "cG9zdDo4NA==",
                    "label": "Buy to let",
                    "parentId": null,
                    "path": "/buy-to-let/",
                    "title": null,
                    "cssClasses": [],
                    "connectedNode": {
                        "node": {
                            "uri": "/buy-to-let/"
                        }
                    }
                }
            },
            {
                "node": {
                    "id": "cG9zdDo4OQ==",
                    "label": "Home mover",
                    "parentId": null,
                    "path": "/home-mover/",
                    "title": null,
                    "cssClasses": [],
                    "connectedNode": {
                        "node": {
                            "uri": "/home-mover/"
                        }
                    }
                }
            }
        ]
    }
};
const servicesMenu = (state = initial, action)=>{
    switch(action.type){
        case 'SERVICES_MENU':
            return state = action.data;
        default: 
            return state;
    }
}

export default servicesMenu;